/* eslint no-underscore-dangle: 0 */

import React from 'react';
import { Snippet } from 'react-instantsearch-dom';

import Link from '@/components/atoms/Link';
import styled from '@emotion/styled';

interface Props {
  hit: any;
}

const SearchHitRoot = styled(Link)`
  display: block;
  text-decoration: none;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary.main};
  }

  & span {
    font-size: 1.4rem;
  }

  & .ais-Snippet-highlighted {
    color: ${({ theme }) => theme.color.primary.main};
    font-weight: 900;
  }
`;

const renderAttribute = (hit: any): string => {
  if (hit._highlightResult.name.matchLevel !== 'none') {
    return 'name';
  }

  if (hit._highlightResult.description.matchLevel !== 'none') {
    return 'description';
  }
  // if (
  //   hit._highlightResult.categories.map(
  //     (cat: any) => cat.name.matchLevel !== 'none',
  //   )
  // ) {
  //   return 'categories';
  // }

  return 'name';
};

const SearchHit = ({ hit }: Props): JSX.Element => {
  const attribute = renderAttribute(hit);

  return (
    <SearchHitRoot to={hit.slug}>
      <Snippet hit={hit} attribute={attribute} />
      ...
    </SearchHitRoot>
  );
};

export default SearchHit;
