import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import mq from '@/styles/mq';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
}

const ProductsHeaderRoot = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  ${mq('sm')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
  }
`;
const Title = styled(Typography)`
  ${mq('md')} {
    text-align: center;
  }

  ${mq('lg')} {
    text-align: left;
  }
`;
const Description = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};

  ${mq('md')} {
    text-align: center;
  }

  ${mq('lg')} {
    text-align: left;
  }
`;

const ProductsHeader = ({ title, description }: Props): JSX.Element => {
  return (
    <ProductsHeaderRoot maxWidth="lg">
      <Title variant="h1">{title}</Title>
      <Description variant="textLg">{description}</Description>
    </ProductsHeaderRoot>
  );
};

export const query = graphql`
  fragment ProductsHeader on PrismicProductsPageDataType {
    page_title {
      raw
    }
    page_description {
      raw
    }
  }
`;

export default ProductsHeader;
