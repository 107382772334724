import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import ProductsContainer from '@/containers/Products/ProductsContainer';

import ProductFiltersProvider from '@/contexts/productFiltersContext';

import { ProductsPageQuery } from '../../../graphql-types';

interface Props {
  data: ProductsPageQuery;
}

export const pageQuery = graphql`
  query ProductsPage {
    allPrismicProduct {
      edges {
        node {
          ...ProductThumbnail
        }
      }
    }
    prismicProductsPageBody1Companystats {
      primary {
        type
      }
      items {
        number {
          raw
        }
        label {
          text
        }
      }
    }
    prismicProductsPageBodyContactbox {
      primary {
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
    allPrismicCategory {
      edges {
        node {
          ...Category
        }
      }
    }
    allPrismicPrimaryCategory {
      edges {
        node {
          id
          uid
          data {
            name {
              raw
            }
            image {
              url
            }
            secondary_categories {
              secondary_category {
                document {
                  ... on PrismicSecondaryCategory {
                    id
                    uid
                    data {
                      name {
                        raw
                      }
                      image {
                        url
                      }
                      tertiary_categories {
                        tertiary_category {
                          document {
                            ... on PrismicTertiaryCategory {
                              id
                              uid
                              data {
                                name {
                                  raw
                                }
                                image {
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicProductsPage {
      data {
        meta_title
        meta_description
        ...ProductsHeader
      }
    }
    prismicCompanyStats {
      data {
        ...CompanyStats
      }
    }
  }
`;

const Products = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } =
    data?.prismicProductsPage?.data || {};

  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title || 'Products page'}
        description={meta_description || 'These are our products'}
      />
      <ProductFiltersProvider>
        <ProductsContainer data={data} type="magasin" />
      </ProductFiltersProvider>
    </Layout>
  );
};

export default Products;
