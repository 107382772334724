import React from 'react';
import { RichText } from 'prismic-reactjs';

import ProductsHeader from '@/containers/Products/ProductsHeader';
import ProductsContactBox from '@/containers/Products/ProductsContactBox';
import ProductsStats from '@/containers/Products/ProductsStats';
import ProductsMain from '@/containers/Products/ProductsMain';
import ProductsCategoriesBox from '@/containers/Products/ProductsCategoriesBox';

import formatProductCategories from '@/utils/formatProductCategories';
import formatProductsThumbnails from '@/utils/formatProductsThumbnails';

import {
  CategoryFragment,
  PrismicCompanyStatsStatsGroupType,
  ProductsPageQuery,
} from '../../../graphql-types';

interface Props {
  data: ProductsPageQuery;
  type: string;
}

const ProductsContainer = ({ data, type }: Props): JSX.Element => {
  const { page_title, page_description } =
    data?.prismicProductsPage?.data || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  //const { stats } = data?.prismicCompanyStats?.data || {};

  const categories = data?.allPrismicCategory?.edges;

  const { primary } = data?.prismicProductsPageBodyContactbox || {};

  const companyStats = data?.prismicProductsPageBody1Companystats || {};

  const Categories = data?.allPrismicPrimaryCategory?.edges;

  const FinalCategories = Categories?.map((primaryCat: any) => {
    return {
      id: primaryCat?.node?.id,
      name: <RichText render={primaryCat?.node?.data?.name?.raw} />,
      secondary: primaryCat?.node?.data?.secondary_categories?.map(
        (secondary: any) => ({
          id: secondary?.secondary_category?.document?.id,
          name: (
            <RichText
              render={secondary?.secondary_category?.document?.data?.name?.raw}
            />
          ),
          tertiary:
            secondary?.secondary_category?.document?.data?.tertiary_categories?.map(
              (tertiary: any) => ({
                id: tertiary?.tertiary_category?.document?.id,
                name: (
                  <RichText
                    render={
                      tertiary?.tertiary_category?.document?.data?.name?.raw
                    }
                  />
                ),
              }),
            ),
        }),
      ),
    };
  });

  const CategoriesBox = Categories?.map((primaryCat: any) => {
    return {
      uid: primaryCat?.node?.uid,
      name: <RichText render={primaryCat?.node?.data?.name?.raw} />,
      image: {
        url: primaryCat?.node?.data?.image?.url,
        alt: primaryCat?.node?.data?.image?.alt,
      },
    };
  });

  const CategoriesBoxProps = {
    categoriesBox: CategoriesBox,
  };

  // const primaryCategories = formatProductCategories(
  //   categories?.filter(
  //     (el: { node: CategoryFragment }) =>
  //       el?.node?.data?.type === ('primary' || null),
  //   ),
  // );

  // const secondaryCategories = formatProductCategories(
  //   categories?.filter(
  //     (el: { node: CategoryFragment }) => el?.node?.data?.type === 'secondary',
  //   ),
  // );

  // const tertiaryCategories = formatProductCategories(
  //   categories?.filter(
  //     (el: { node: CategoryFragment }) => el?.node?.data?.type === 'tertiary',
  //   ),
  // );

  // const primaryCategories = formatProductCategories(

  // )

  const products = formatProductsThumbnails(data?.allPrismicProduct?.edges);

  const HeaderProps = {
    title: <RichText render={page_title?.raw} />,
    description: <RichText render={page_description?.raw} />,
  };

  const ListProps = {
    categories: FinalCategories,
    products,
  };
  // const ListProps = {
  //   categories: {
  //     primary: primaryCategories,
  //     secondary: secondaryCategories,
  //     tertiary: tertiaryCategories,
  //   },
  //   products,
  // };

  // const ContactBoxProps = {
  //   background: {
  //     url: contact_box_background?.url,
  //     alt: contact_box_background?.alt,
  //   },
  //   title: <RichText render={contact_box_section_title?.raw} />,
  //   content: <RichText render={contact_box_content?.raw} />,
  // };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  return (
    <>
      <ProductsHeader {...HeaderProps} />
      <ProductsCategoriesBox {...CategoriesBoxProps} />
      <ProductsMain {...ListProps} />
      {primary && <ProductsContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && <ProductsStats {...StatsProps} />}
    </>
  );
};

export default ProductsContainer;
