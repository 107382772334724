import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import styled from '@emotion/styled';

import { StateResultsProvided } from 'react-instantsearch-core';

interface ResultsProps extends StateResultsProvided {
  children?: React.ReactNode;
}

const NoResults = styled('p')`
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

const QueryTerm = styled('span')`
  font-weight: 900;
  font-style: italic;
`;

const SearchResults = connectStateResults(
  ({ searchState, searchResults, children }: ResultsProps): any | null => {
    return searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <NoResults>
        Pas de résultats pour <QueryTerm>{searchState.query}</QueryTerm>
      </NoResults>
    );
  },
);

export default SearchResults;
