import React, { HTMLAttributes, useRef, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  Configure,
  Hits,
  InstantSearch,
  SearchBox,
} from 'react-instantsearch-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';

import SearchResults from '@/components/atoms/SearchResults';
import SearchHit from '@/components/atoms/SearchHit';
import useOnClickOutside from '@/hooks/useOutsideClick';
import mq from '@/styles/mq';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID || '',
  process.env.GATSBY_ALGOLIA_SEARCH_KEY || '',
);

const SearchRoot = styled('div')`
  position: relative;
`;
const SearchBoxStyled = styled(SearchBox)`
  position: relative;
  & input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  & input {
    background-color: ${({ theme }) => theme.color.secondary.dark};
    border: none;
    padding-top: ${({ theme }) => theme.spacing(1.5)};
    padding-bottom: ${({ theme }) => theme.spacing(1.5)};
    padding-left: ${({ theme }) => theme.spacing(7)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    min-width: 26rem;
    width: 100%;
    border-radius: 5px;

    &::placeholder {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.color.textSecondary.main};
      opacity: 0.5;
    }

    ${mq('lg')} {
      padding-left: ${({ theme }) => theme.spacing(4)};
    }
  }

  & button {
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);
    width: 1.5rem;
    padding: 0;
    background-color: transparent;
  }
`;
const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.color.black.main};
  cursor: pointer;
  font-size: 1.4rem;
`;
const ResultsContainer = styled('div')`
  background-color: ${({ theme }) => theme.color.white.main};
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  position: absolute;
  top: 103%;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.color.primary.main};
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
`;
const ShowResults = css`
  display: block;
`;
const HideResults = css`
  display: none;
`;

const Search = ({ ...rest }: HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const [hasInput, setHasInput] = useState(false);
  const resultsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(resultsRef, () => setHasInput(false));

  const handleKeyUp = (event: KeyboardEvent): void => {
    event.preventDefault();
    const target = event.currentTarget as HTMLInputElement;

    setHasInput(target.value !== '');
  };

  return (
    <SearchRoot {...rest}>
      <InstantSearch
        searchClient={searchClient}
        indexName={`${process.env.GATSBY_ALGOLIA_INDEXNAME}`}
      >
        <Configure
          attributesToSnippet={['name:5', 'description:5', 'categories:5']}
          hitsPerPage={5}
        />
        <SearchBoxStyled
          submit={<Icon icon={faSearch} />}
          reset={<div />}
          // reset={hasInput ? <Icon icon={faTimes} /> : <div />}
          translations={{
            placeholder: 'Rechercher parmis nos produits',
          }}
          // @ts-ignore
          onKeyUp={handleKeyUp}
        />
        <ResultsContainer
          ref={resultsRef}
          css={hasInput ? ShowResults : HideResults}
        >
          <SearchResults>
            <Hits hitComponent={SearchHit} />
          </SearchResults>
        </ResultsContainer>
      </InstantSearch>
    </SearchRoot>
  );
};

export default Search;
