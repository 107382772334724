import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';

import Grid from '@/components/atoms/Grid';
import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import ProductCategories from '@/components/molecules/ProductCategories';
import ProductsList from '@/components/molecules/ProductsList';
import Search from '@/components/atoms/Search';

import { useProductFiltersContext } from '@/contexts/productFiltersContext';

import mq from '@/styles/mq';

import { ProductThumbnailType } from '@/domain/entities/Product';
import { CategoriesProps } from '@/components/molecules/ProductCategories/ProductCategories';

interface Props {
  categories?: CategoriesProps;
  products?: ProductThumbnailType[];
}

const ProductsMainRoot = styled(Container)``;
const ParentGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
const CategoriesDesktopGrid = styled(Grid)`
  display: none;
  ${mq('lg')} {
    display: block;
  }
`;
const CategoriesMobileGrid = styled(Grid)`
  order: 3;
  margin-top: ${({ theme }) => theme.spacing(2)};
  ${mq('lg')} {
    display: none;
  }
`;
// const CategoriesMobile = styled(Grid)`
//   /* order: 3;
//   margin-top: ${({ theme }) => theme.spacing(2)};
//   margin-left: auto;
//   margin-right: auto;
//   width: 100%;
//   max-width: 100%;
//   height: auto;
//   ${mq('lg')} {
//     display: none;
//     max-width: 100%;
//   } */
//   display: block;

//   ${mq('lg')} {
//     display: none;
//   }
// `;
const ProductsGrid = styled(Grid)`
  ${mq('lg')} {
    padding-left: ${({ theme }) => theme.spacing(4)};
  }
`;
const SearchContainer = styled('div')`
  display: flex;
  flex-direction: column;

  ${mq('lg')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const Results = styled(Typography)`
  order: 2;
  margin-top: ${({ theme }) => theme.spacing(1)};

  ${mq('md')} {
    text-align: center;
  }

  ${mq('lg')} {
    text-align: left;
    order: 1;
    margin-top: 0;
  }
`;
const SearchBar = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  order: 1;

  ${mq('lg')} {
    order: 2;
  }
`;
const SearchStyled = styled(Search)`
  width: 100%;
  ${mq('md')} {
    width: 300px;
  }
`;
const FilterButton = styled('button')`
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  margin-left: ${({ theme }) => theme.spacing(1)};
  ${mq('lg')} {
    display: none;
  }
`;

const ProductsMain = ({ categories, products }: Props): JSX.Element => {
  const { productFilters, setProductFilters } = useProductFiltersContext();
  const [open, setOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();

  const filteredProducts: ProductThumbnailType[] | undefined =
    productFilters.length === 0
      ? products
      : products?.filter((product) =>
          productFilters?.every((filter) =>
            product?.categories?.map((cat) => cat?.id)?.includes(filter),
          ),
        );

  useEffect(() => {
    const setDimensions = () => {
      const winWidth = typeof window !== 'undefined' && window.innerWidth;
      setWidth(winWidth as number);
    };
    setDimensions();
    window.addEventListener('resize', setDimensions);
    const id = localStorage.getItem('categoryId');
    if (width !== undefined && width > 1280) {
      setOpen(false);
    }
    if (id && width !== undefined && width < 1280) {
      setOpen(true);
    }
    return () => window.removeEventListener('resize', setDimensions);
  });

  useEffect(() => {
    const id = localStorage.getItem('categoryId');
    if (id) {
      const ProductsMainTop = document
        .querySelector('#ProductsMainRoot')
        ?.getBoundingClientRect()?.top;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      typeof window !== 'undefined' &&
        window.scrollTo(0, ProductsMainTop - 100);
    }
  }, []);

  const handleClick = () => setOpen(!open);

  return (
    <ProductsMainRoot maxWidth="lg" id="ProductsMainRoot">
      <ParentGrid container>
        {!open && (
          <CategoriesDesktopGrid lg={3}>
            <ProductCategories categories={categories} />
          </CategoriesDesktopGrid>
        )}
        <ProductsGrid xxs={12} lg={9}>
          <SearchContainer>
            <Results color="textPrimary">
              {filteredProducts?.length} résultats
            </Results>
            <SearchBar>
              <SearchStyled />
              <FilterButton onClick={handleClick}>
                <FontAwesomeIcon icon={faSlidersH} />
              </FilterButton>
            </SearchBar>
            {open && (
              <CategoriesMobileGrid lg={12}>
                <ProductCategories categories={categories} />
              </CategoriesMobileGrid>
            )}
          </SearchContainer>
          <ProductsList products={filteredProducts} />
        </ProductsGrid>
      </ParentGrid>
    </ProductsMainRoot>
  );
};

export default ProductsMain;
