import React from 'react';
import styled from '@emotion/styled';

import ProductThumbnail from '@/components/molecules/ProductThumbnail';
import Grid from '@/components/atoms/Grid';

import mq from '@/styles/mq';

import { ProductThumbnailType } from '@/domain/entities/Product';

interface Props {
  products?: ProductThumbnailType[];
}

const ProductGrid = styled(Grid)`
  display: flex;
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(3.5)};
  padding-right: ${({ theme }) => theme.spacing(3.5)};
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;
const ProductsListRoot = styled(Grid)`
  align-items: stretch;
  margin-top: ${({ theme }) => theme.spacing(2)};
  overflow-y: scroll;
  height: 1110px;
  ${mq('md')} {
    height: 1110px;
  }
  ${mq('lg')} {
    height: 1830px;
  }
  & ${ProductGrid} {
    ${mq('md')} {
      border-right: 2px solid ${({ theme }) => theme.color.secondary.main};
    }
  }

  & ${ProductGrid}:nth-of-type(2n) {
    ${mq('md')} {
      border-right: none;
    }

    ${mq('lg')} {
      border-right: 2px solid ${({ theme }) => theme.color.secondary.main};
    }
  }

  & ${ProductGrid}:nth-of-type(3n) {
    ${mq('lg')} {
      border-right: none;
    }
  }

  & ${ProductGrid}:nth-of-type(n+3) {
    ${mq('md')} {
      border-top: 2px solid ${({ theme }) => theme.color.secondary.main};
    }

    ${mq('lg')} {
      border-top: none;
    }
  }

  & ${ProductGrid}:nth-of-type(n+4) {
    ${mq('lg')} {
      border-top: 2px solid ${({ theme }) => theme.color.secondary.main};
    }
  }
`;

const ProductsList = ({ products }: Props): JSX.Element => {
  const Products = products?.map((product, i) => (
    <ProductGrid key={i.toString()} xxs={12} md={6} lg={4}>
      <ProductThumbnail {...product} />
    </ProductGrid>
  ));

  return <ProductsListRoot container>{Products}</ProductsListRoot>;
};

export default ProductsList;
