import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Img from '@/components/atoms/Img';
import Typography from '@/components/atoms/Typography';
import Link from '@/components/atoms/Link';
import Button from '@/components/atoms/Button';
import Grid from '@/components/atoms/Grid';
import Container from '@/components/atoms/Container';

import mq from '@/styles/mq';

interface CategoryProps {
  uid: string;
  name: React.ReactNode;
  image: {
    url: string;
    alt: string;
  };
}

interface ProductsCategoriesProps {
  categoriesBox: CategoryProps[];
}

const ProductsCategoriesBoxRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
const PrimaryCategoriesContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;
const CategoryRoot = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CategoryLink = styled(Link)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  width: 100%;
  ${mq('xxs')} {
    width: 30%;
  }
  ${mq('xs')} {
    width: 23%;
  }
  ${mq('md')} {
    width: 18%;
  }
`;
const Image = styled(Img)`
  border-radius: 10px;
  width: 120px;
  height: 120px;
  ${mq('xxs')} {
    width: 100px;
    height: 100px;
  }
  ${mq('xs')} {
    width: 100px;
    height: 100px;
  }
  ${mq('md')} {
    width: 150px;
    height: 150px;
  }
`;
const Name = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  font-weight: 800;
`;

const Category = ({ uid, image, name }: CategoryProps): JSX.Element => {
  const { url, alt } = image || {};
  return (
    <CategoryLink to={`/magasin/produits/${uid}`}>
      <CategoryRoot>
        {url && <Image src={url} alt={alt || ''} sizes="200px" />}
        <Name variant="textMd">{name}</Name>
      </CategoryRoot>
    </CategoryLink>
  );
};

const ProductsCategoriesBox = ({
  categoriesBox,
}: ProductsCategoriesProps): JSX.Element => {
  return (
    <ProductsCategoriesBoxRoot maxWidth="lg">
      <PrimaryCategoriesContainer>
        {categoriesBox?.map((category: any) => (
          <Category {...category} />
        ))}
      </PrimaryCategoriesContainer>
    </ProductsCategoriesBoxRoot>
  );
};

export default ProductsCategoriesBox;
