import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface ProductFiltersContextType {
  productFilters: string[];
  setProductFilters: Dispatch<SetStateAction<string[]>>;
}

interface ProviderProps {
  children: React.ReactNode;
}

const initialValue: ProductFiltersContextType = {
  productFilters: [],
  setProductFilters: () => null,
};

export const ProductFiltersContext =
  createContext<ProductFiltersContextType>(initialValue);

const ProductFiltersProvider = ({ children }: ProviderProps): JSX.Element => {
  const [productFilters, setProductFilters] = useState<string[]>([]);

  const value = useMemo(
    () => ({ productFilters, setProductFilters }),
    [productFilters, setProductFilters],
  );

  return (
    <ProductFiltersContext.Provider value={value}>
      {children}
    </ProductFiltersContext.Provider>
  );
};

export const useProductFiltersContext = (): ProductFiltersContextType =>
  useContext(ProductFiltersContext);

export default ProductFiltersProvider;
